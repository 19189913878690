import React, { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {
	Button,
	Card,
	Form,
	Input,
	Space,
	Typography,
	message,
	notification,
} from 'antd';
import { withRouter, useHistory, Link } from 'react-router-dom';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import './create-setting.scss';

const CreateSetting = () => {
	let history = useHistory();
	const tailLayout = {
		wrapperCol: {
			offset: 4,
			span: 12,
		},
	};

	const [form] = Form.useForm();
	const delay = (ms) => new Promise((res) => setTimeout(res, ms));
	const openNotificationWithIcon = (type, errorDetail) => {
		switch (type) {
			case 'success':
				notification[type]({
					message: 'Create setting successfully',
				});

				break;
			case 'error':
				notification[type]({
					message: 'Create setting fail',
					description: errorDetail,
				});
				break;
			default:
				break;
		}
	};

	const onFinish = async (values) => {
		console.log(JSON.stringify(values));

		await axiosConfig({
			method: 'post',
			data: values,
			url: `/api/roxuslogging/clients/setting-new`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then(async (response) => {
				console.log(response);
				openNotificationWithIcon('success');
				await delay(3000);
				history.push('/rpa/settings');
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				openNotificationWithIcon('error', error.response.data);
			});
	};

	const onReset = () => {
		form.resetFields();
	};

	const changePage = (page) => {
		switch (page) {
			case 'setting-list':
				history.push('/rpa/process-config');
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.title = 'Roxus | Create Setting';
	}, []);

	return (
		<>
			<div className='ocr-list-page'>
				<div className='ocr-title'>
					<span className='text'>CREATE SETTING</span>
				</div>
			</div>
			<Form
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={form}
				name='dynamic_form_complex'
				onFinish={onFinish}
				autoComplete='off'
				initialValues={{
					items: [{}],
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<>
						<Form.Item
							label='Client Name'
							name='ClientName'
							rules={[
								{
									required: true,
									message: 'Client Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label='Process Name'
							name='ProcessName'
							rules={[
								{
									required: true,
									message: 'Process Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label='Trigger Name' name='TriggerName'>
							<Input />
						</Form.Item>
						{/* Nest Form.List */}
						<Form.Item label='Config'>
							<Form.List name={'Configs'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Config ${index + 1
															}`}
													/>
												</Form.Item>
												<Form.Item
													name={[
														subField.name,
														'Value',
													]}
													style={{
														width: '600px',
													}}
												>
													<Input
														placeholder={`Value ${index + 1
															}`}
													/>
												</Form.Item>
												<CloseOutlined
													style={{ color: '#ff4d4f' }}
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Config
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
					</>
				</div>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
					<Button htmlType='button' onClick={onReset}>
						Clear
					</Button>
					<Button
						htmlType='button'
						onClick={() => changePage('setting-list')}
					>
						Don't Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default CreateSetting;
